export enum GoalUid {
  // MARKET_CAPITALIZATION = 'MARKET_CAPITALIZATION',
  // IPO_READINESS = 'IPO_READINESS',
  EFFICIENT_GROWTH = 'EFFICIENT_GROWTH',
  REVENUE_GROWTH = 'REVENUE_GROWTH',
}

export type GoalDescription = {
  uid: GoalUid;
  name: string;
};
